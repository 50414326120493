import Axios from 'axios';
import { _removeToken, _storeLoginInfo } from './Cookie';

const DefaultAxios = Axios.create();

// DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
DefaultAxios.interceptors.request.use(config => {
    if (!config.headers.Authorization) {
        try {
            const token = localStorage.getItem("token")

            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
        } catch {
            // console.error('Cookies disabled')
        }
    }
    return config
})

DefaultAxios.interceptors.response.use(response => {
    return response;
}, error => {
    const ignore = error?.response?.config?.url.match(/(login|recover|reset)/);
    if (error?.response?.status === 401 && !ignore) { // Invalid token
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('expired_in')
            localStorage.removeItem('jendela_username')
            _removeToken()
            // window.location.href = '/';
        } catch {
            // console.error('Cookies disabled')
        }
    } else if (error?.response?.status === 426) { // Expired token, try to refresh first
        try {
            const originalRequestConfig = error.config
            if (!originalRequestConfig._retry) {
                originalRequestConfig._retry = true

                return Axios
                    .post(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/refresh`, null, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                    .then(res => {
                        localStorage.setItem('token', res.data);
                        if (res.data) _storeLoginInfo(res.data, 3600)
                        originalRequestConfig.headers['Authorization'] = `Bearer ${res.data}`
                        DefaultAxios.defaults.headers.common['Authorization'] = `Bearer ${res.data}`;

                        return DefaultAxios.request(originalRequestConfig);
                    })
                    .catch(error => {
                        if (error?.response?.status === 426 || error?.response?.status === 401) {
                            try {
                                localStorage.removeItem('token');
                                localStorage.removeItem('expired_in')
                                localStorage.removeItem('jendela_username')
                                _removeToken()

                                window.location.reload()
                            } catch {
                                // console.error('Cookies disabled')
                            }
                        }
                    })
                // .finally(() => {
                //     sessionStorage.removeItem('prevent_multiple_refresh');
                // })
            } else {
                originalRequestConfig.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                return DefaultAxios.request(originalRequestConfig);
            }
        } catch {
            // console.error('Cookies disabled')
        }
    } else if (error?.response?.status === 403) {
        window.location.href = '/';
    }
    return Promise.reject(error);
})

export default DefaultAxios;
