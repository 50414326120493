import React, { useEffect, useMemo, useState } from 'react'
import { popularApartment } from '../../../utils/StaticData'
import PopularApartment from '../../cards/PopularApartment'
import Button from '../../forms/Button'
import { generateUrl, translateDirectionLocation } from '../../../utils/Helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { IApartmentPopular } from '../Index'
import DefaultAxios from '../../../utils/DefaultAxios'
import PopularApartmentSkeleton from './PopularApartmentSkeleton'

type Props = {
    lang: any
    langCode: string
    initialData: IApartmentPopular[]
}

const areas = [
    { label: 'Jakarta Selatan', value: 'jakarta-selatan' },
    { label: 'Jakarta Barat', value: 'jakarta-barat' },
    { label: 'Jakarta Timur', value: 'jakarta-timur' },
    { label: 'Jakarta Pusat', value: 'jakarta-pusat' },
    { label: 'Jakarta Utara', value: 'jakarta-utara' },
    { label: 'Tangerang', value: 'tangerang' },
    { label: 'Bekasi', value: 'bekasi' },
]

function PopularApartmentSection({ lang, langCode, initialData }: Props) {
    const [selectedArea, setSelectedArea] = useState('jakarta-selatan')
    const [apartmentPopular, setApartmentPopular] = useState(initialData.length ? initialData : [])
    const [loading, setLoading] = useState(initialData.length ? false : true)

    const fetchApartmentPerSlug = (slug: string) => {
        setLoading(true)
        DefaultAxios.get(`${`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/popular-apartment-homepage?area_slug=${slug}`}`)
            .then(res => res.data)
            .then(res => setApartmentPopular(res))
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChangeArea = (area: string) => {
        setSelectedArea(area)
        fetchApartmentPerSlug(area)
    }

    const renderList = useMemo(() => {
        const defaultRender = apartmentPopular.map((apartment, idx) =>
            <PopularApartment
                key={idx}
                img={apartment.primary_image_thumbnail_path}
                title={apartment.name}
                location={apartment.location}
                price={String(apartment.price_start)}
                url={apartment.url}
                index={idx}
                lang={lang}
                lang_code={langCode}
            // star={star}
            />
        )

        const loadingCard = [1,2,3,4,5,6,7,8].map(() => (
            <PopularApartmentSkeleton />
        ))

        if (loading) {
            return loadingCard
        }

        return defaultRender
    }, [loading, apartmentPopular])

    return (
        <>
            <h3 className="text-2xl mb-4 font-bold">{lang.popular_apartment}</h3>
            <div className='flex flex-row gap-10px mb-5 overflow-y-scroll no-scrollbar-all' style={{ scrollSnapType: 'x mandatory' }}>
                {
                    areas.map((area) => (
                        <button
                            onClick={() => handleChangeArea(area.value)} key={area.value} className={`flex-0 whitespace-no-wrap outline-none focus:outline-none cursor self-start py-10px px-6 text-sm border rounded-full ${selectedArea === area.value ? 'text-white bg-main border-transparent' : 'text-#727272 bg-white border-gray-dd'} `}
                            style={{ scrollSnapAlign: 'start' }}
                        >
                            {translateDirectionLocation(area.label, langCode)}
                        </button>
                    ))
                }
            </div>
            <div className="flex flex-row -mx-3 lg:flex-wrap flex-no-wrap overflow-y-scroll no-scrollbar-all" style={{ scrollSnapType: 'x mandatory' }}>
                {renderList}
            </div>
            <div className='flex lg:justify-center lg:items-center justify-start items-start'>
                <Button href={generateUrl(langCode, '/apartemen')} variant="outlined" fontColor="text-main" fontSize="text-sm" classes="flex flex-row lg:p-3 lg:rounded-full border lg:border-main border-transparent font-semibold items-center hover:text-main-dark active:text-main-dark" >
                    {lang.view_all}
                    <FontAwesomeIcon icon={faArrowRight} className='text-main ml-2 lg:block hidden' />
                </Button>
            </div>
        </>
    )
}

export default PopularApartmentSection